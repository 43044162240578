import Vue from "vue";
// @ts-ignore
import HeaderTableDelivery from "@/views/Admin/Modifiers/List/HeaderTableDelivery.vue";
// @ts-ignore
import { debounce, isEmpty, isNull, isObject } from "lodash";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
//@ts-ignore
import RadioButton from "@/components/Content/RadioButton.vue";
// @ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
import { mapActions, mapGetters } from "vuex";
import { TypeLoading } from "@/interfaces/loading";
import { getModifiersOptions } from "@/views/Admin/Modifiers/Logic/utils";

const excludesColums = ['rank', 'weight', 'porcentaje', 'delete'];

export default Vue.extend({
  name: "TableDelivery",
  props: {
    headers: {
      type: Array,
      default: []
    },
    items: {
      type: Array,
      default: [],
    },
    fallback_weight: String,
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    HeaderTableDelivery,
    CardTextField,
    ButtonTooltip,
    CardAutocomplete,
    RadioButton
  },
  data: () => ({
    resources: {
      ad_size: [],
      app_bundle_list: [],
      app_name: [],
      ad_position: [],
      environment_type: [],
      interstitial_flags:[],
      interstitial_type: [],
      topframe: [],
      video_api: [],
      content_rating: [],
      language: [],
      auction_type: [],
      companion_required: [],
      inventory_source: [],
      playback_method: [],
      player_size: [],
      start_delay: [],
      video_placement_type: []
    }
  }),
  created() {
    this.getAppNameData = debounce(async function (term) {
      if (isNull(term) || (term && term.length < 3)) return;
      await this.fetchingByAttribute(
        "app_name",
        term,
        {
          key: "app_name",
          value: "app_name",
        }
      );
    }, 750)
  },
  mounted() {
    this.$nextTick(async () => {
      await this.fetchAppBundleList();
      await this.SearchResources();
    });
  },
  computed: {
    ...mapGetters("modifier_options", ["getModifierOption"]),

    getSlot() {
      return this.items.map((v) => v.key);
    },
    getItems() {
      return this.items.map(item => {
        item.targeting.forEach(async (target) => {
          item[target.key] = target;
          item[target.key]['config'] = this.getConfig(target.key);
        })
        this.calculo(item);
        return item
      });
    },
    computedFallback: {
      get() {
        return isNaN(this.fallback_weight) ? "" : this.fallback_weight
      },
      set(value) {
        this.$emit('fallback_change', value)
      }
    },
    getFallback() {
      return {
        percent: ((parseFloat(this.computedFallback) * 100) / this.total).toFixed(2)
      }
    },
    total() {
      return this.items.reduce(
        (accum, item) => accum + parseFloat(item.weight),
        parseFloat(this.computedFallback) || 0,
      ).toFixed(2);
    },
    getHeaderTable(){
      return this.headers.map(h => {
        let item = {};
        item['text'] = h.text;
        item['value'] = h.value;
        item['component'] = !excludesColums.includes(h.value); 
        return item;
      });
    },
    getItemsTable() {
      return getModifiersOptions();
    },
    getRadioButtonItems() {
			return this.resources.interstitial_flags.map((d: any) => {
				return {
					id: d.extra,
					value: d.description
				}
			});
		}
  },
  methods: {
    ...mapActions("proccess", ["setLoadingField"]),
    ...mapActions("targeting", ["getTargetingKey", "getAppNameByAtribute"]),
    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions("modifier_options", [	
                      "getPositions", 
											"getSizes",
											"getEnvironmentTypes",
											"getInterstitialFlags",
											"getInterstitialTypes",
											"getAPIFrameworks",
											"getTopFrame",
                      "getAuctionTypes",
                      "getInventorySources",
                      "getCompanionSupports",
                      "getVideoPlaybackMethods",
                      "getPlayerSizes",
                      "getStartDelays",
                      "getVideoPlacements",
                      "getLanguages",
                      "getContentRatings"
		                ]),

    execute(callback){
      callback;
    },

    getConfig(target: any){
      return this.getConfigModifersOptions()[target];
    },

    resourcesAppName(item) {
      if (isNull(item.app_name.value)) {
        return this.resources.app_name
      } else {
        return [
          ...this.resources.app_name,
          {
            id: item.app_name.value,
            value: item.app_name.value
          }
        ]
      }
    },

    async handleRemoveColumn(column) {
      this.$emit("delete-column", column.value);
    },

    async handleRemoveItem(item) {
      this.$emit("delete-item", item);
    },

    async SearchResources(){
      this.items.map(item => {
        item.targeting.forEach(async (target) => {
            await this.fetchData(String(target.key));
        });
      });
    },

    async fetchAppBundleList(){
      if(isEmpty(this.resources.app_bundle_list)){
        if(!isEmpty(this.getModifierOption['app_bundle_list'])){
          this.resources.app_bundle_list = this.getModifierOption['app_bundle_list'];
        }else{
          await this.setLoadingData(TypeLoading.loading);
          this.resources.app_bundle_list = await this.getTargetingKey({ key: "app_bundle_list" });
          await this.setLoadingData();
        }
      }
    },

    async fetchContentRating(){
      if(isEmpty(this.resources.content_rating)){
        if(!isEmpty(this.getModifierOption['content_rating'])){
          this.resources.content_rating = this.getModifierOption['content_rating'];
        }else{
          await this.setLoadingData(TypeLoading.loading);
          this.resources.content_rating = await this.getContentRatings();
          await this.setLoadingData();
        }
      }
    },

    async fetchLanguage(){
      if(isEmpty(this.resources.language)){
        if(!isEmpty(this.getModifierOption['language'])){
          this.resources.language = this.getModifierOption['language'];
        }else{
          await this.setLoadingData(TypeLoading.loading);
          this.resources.language = await this.getLanguages();
          await this.setLoadingData();
        }
      }
    },

    async fetchAdSize(){
      if(isEmpty(this.resources.ad_size)){
        await this.setLoadingData(TypeLoading.loading);
        this.resources.ad_size = await this.getSizes();
        await this.setLoadingData();
      }
    },

    async fetchAdPosition(){
      if(isEmpty(this.resources.ad_position)){
        await this.setLoadingData(TypeLoading.loading);
        this.resources.ad_position = await this.getPositions();
        await this.setLoadingData();
      }
    },

    async fetchEnvironmentType(){
      if(isEmpty(this.resources.environment_type)){
        await this.setLoadingData(TypeLoading.loading);
        this.resources.environment_type = await this.getEnvironmentTypes();
        await this.setLoadingData();
      }
    },

    async fetchInterstitialFlag(){
      if(isEmpty(this.resources.interstitial_flags)){
        await this.setLoadingData(TypeLoading.loading);
        this.resources.interstitial_flags = await this.getInterstitialFlags();
        await this.setLoadingData();
      }
    },

    async fetchInterstitialType(){
      if(isEmpty(this.resources.interstitial_type)){
        await this.setLoadingData(TypeLoading.loading);
        this.resources.interstitial_type = await this.getInterstitialTypes();
        await this.setLoadingData();
      }
    },

    async fetchTopFrame(){
      if(isEmpty(this.resources.topframe)){
        await this.setLoadingData(TypeLoading.loading);
        //this.resources.topframe = await this.getTargetingKey({ key: "app_bundle_list" });
        await this.setLoadingData();
      }
    },

    async fetchVideoApi(){
      if(isEmpty(this.resources.video_api)){
        await this.setLoadingData(TypeLoading.loading);
        //this.resources.video_api = await this.getTargetingKey({ key: "app_bundle_list" });
        await this.setLoadingData();
      }
    },

    async fetchAuctionType(){
      if(isEmpty(this.resources.auction_type)){
        await this.setLoadingData(TypeLoading.loading);
        this.resources.auction_type = await this.getAuctionTypes();
        await this.setLoadingData();
      }
    },

    async fetchInventorySource(){
      if(isEmpty(this.resources.inventory_source)){
        await this.setLoadingData(TypeLoading.loading);
        this.resources.inventory_source = await this.getInventorySources();
        await this.setLoadingData();
      }
    },

    async fetchCompanionRequired(){
      if(isEmpty(this.resources.companion_required)){
        await this.setLoadingData(TypeLoading.loading);
        this.resources.companion_required = await this.getCompanionSupports();
        await this.setLoadingData();
      }
    },

    async fetchPlaybackMethod(){
      if(isEmpty(this.resources.playback_method)){
        await this.setLoadingData(TypeLoading.loading);
        this.resources.playback_method = await this.getVideoPlaybackMethods();
        await this.setLoadingData();
      }
    },

    async fetchPlayerSize(){
      if(isEmpty(this.resources.player_size)){
        await this.setLoadingData(TypeLoading.loading);
        this.resources.player_size = await this.getPlayerSizes();
        await this.setLoadingData();
      }
    },

    async fetchStartDelay(){
      if(isEmpty(this.resources.start_delay)){
        await this.setLoadingData(TypeLoading.loading);
        this.resources.start_delay = await this.getStartDelays();
        await this.setLoadingData();
      }
    },

    async fetchVideoPlacementType(){
      if(isEmpty(this.resources.video_placement_type)){
        await this.setLoadingData(TypeLoading.loading);
        this.resources.video_placement_type = await this.getVideoPlacements();
        await this.setLoadingData();
      }
    },

    async fetchData(key: string){
      switch (key) {
        case "app_bundle_list":
            await this.fetchAppBundleList();
          break;
        case "app_name":
          break;
        case "content_rating":
            await this.fetchContentRating();
          break;
        case "language":
            await this.fetchLanguage();
          break;
        case "ad_size":
            await this.fetchAdSize();
          break;
        case "ad_position":
            await this.fetchAdPosition();
          break;
        case "environment_type":
            await this.fetchEnvironmentType();
          break;
        case "interstitial":
            await this.fetchInterstitialFlag();
          break;
        case "interstitial_type":
            await this.fetchInterstitialType();
          break;
        case "topframe":
            await this.fetchTopFrame();
          break;
        case "video_api":
            await this.fetchVideoApi();
          break;
        case "auction_type":
            await this.fetchAuctionType();
          break;
        case "inventory_source":
            await this.fetchInventorySource();
          break;
        case "companion_required":
            await this.fetchCompanionRequired();
          break;
        case "playback_method":
            await this.fetchPlaybackMethod();
          break;
        case "player_size":
            await this.fetchPlayerSize();
          break;
        case "start_delay":
            await this.fetchStartDelay();
          break;
        case "video_placement_type":
            await this.fetchVideoPlacementType();
          break;
        default:
          break;
      }
    },

    async fetchingByAttribute(
      key: any,
      term: any,
      object: { key: any; value: any }
    ) {
      await this.setLoadingData(TypeLoading.loading);
      const result: Array<any> = await this.getAppNameByAtribute({
        key,
        term,
        by_attribute: object.key,
        object,
      });
      await this.setLoadingData();
      this.resources[key] = result;
    },

    calculo(item: any) {
      let result = (item.weight * 100) / this.total;

      if (isNaN(result)) {
        item["porcentaje"] = 100;
        return result;
      }

      item["porcentaje"] = result.toFixed(2);
    },

    handleChange(item, value) {
      const _ID: any | null = isObject(value) ? value["id"] : value;
      if (!isNull(item)) {
        item.value = _ID;
        item.appNameTerm = ""
      }
    },

    getPlaceHolder(items: any, placeholder: any){
      if(isEmpty(items)){
        return "No Data";
      }
      return placeholder;
    },

    isIntertitials(items: any){
      return items.key == "interstitial";
    },

    isAppName(items: any){
      return items.key == "app_name";
    },

    isNumeric(items: any){
      return items.config.isNumeric && items.config.isFocus
    },

    async syncDataAppName(item, term: string | null = null) {
      item.appNameTerm = term
      await this.getAppNameData(term, item);
    },

    getConfigModifersOptions(){
      return {
        //App/Site
        app_bundle_list: {
          model: "app_bundle_list",
          placeholder: "Any Value",
          resource: "app_bundle_list",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "value",
          value: "id",
          module_name:"App/Site",
          callbackFocus: () => (this.fetchAppBundleList())
        },
        app_name: {
          model: "app_name",
          placeholder: "Any Value",
          resource: "app_name",
          isNumeric: false,
          isFocus: false,
          isSearch: false,
          text: "value",
          value: "id",
          module_name:"App/Site",
          callbackFocus: () => ({})
        },
        //Content
        content_rating: {
          model: "content_rating",
          placeholder: "Any Value",
          resource: "content_rating",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "description",
          value: "extra",
          module_name:"Content",
          callbackFocus: () => (this.fetchContentRating())
        },
        language: {
          model: "language",
          placeholder: "Any Value",
          resource: "language",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "description",
          value: "extra",
          module_name:"Content",
          callbackFocus: () => (this.fetchLanguage())
        },
        ad_size: {
          model: "ad_size",
          placeholder: "Any Value",
          resource: "ad_size",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "name",
          value: "id",
          module_name:"Environment",
          callbackFocus: () => (this.fetchAdSize())
        },
        ad_position: {
          model: "content_category",
          placeholder: "Any Value",
          resource: "content_category",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "description",
          value: "external_id",
          module_name:"Environment",
          callbackFocus: () => (this.fetchAdPosition())
        },
        environment_type: {
          model: "environment_type",
          placeholder: "Any Value",
          resource: "environment_type",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "description",
          value: "extra",
          module_name:"Environment",
          callbackFocus: () => (this.fetchEnvironmentType())
        },
        interstitial: {
          model: "interstitial",
          placeholder: "Any Value",
          resource: "interstitial_flags",
          isNumeric: false,
          isFocus: false,
          isSearch: false,
          text: "description",
          value: "id",
          module_name:"Environment",
          callbackFocus: () => (this.fetchInterstitialFlag())
        },
        interstitial_type: {
          model: "interstitial_type",
          placeholder: "Any Value",
          resource: "interstitial_type",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "value",
          value: "key",
          module_name:"Environment",
          callbackFocus: () => (this.fetchInterstitialType())
        },
        topframe: {
          model: "topframe",
          placeholder: "Any Value",
          resource: "topframe",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "description",
          value: "id",
          module_name:"Environment",
          callbackFocus: () => (this.fetchTopFrame())
        },
        video_api: {
          model: "video_api",
          placeholder: "Any Value",
          resource: "video_api",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "description",
          value: "extra",
          module_name:"Environment",
          callbackFocus: () => (this.fetchVideoApi())
        },
        //Exchange
        auction_type: {
          model: "auction_type",
          placeholder: "Any Value",
          resource: "auction_type",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "description",
          value: "extra",
          module_name:"Exchange",
          callbackFocus: () => (this.fetchAuctionType())
        },
        inventory_source: {
          model: "inventory_source",
          placeholder: "Any Value",
          resource: "inventory_source",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "name",
          value: "key",
          module_name:"Exchange",
          callbackFocus: () => (this.fetchInventorySource())
        },
        //Video
        companion_required: {
          model: "companion_required",
          placeholder: "Any Value",
          resource: "companion_required",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "description",
          value: "extra",
          module_name:"Video",
          callbackFocus: () => (this.fetchCompanionRequired())
        },
        playback_method: {
          model: "playback_method",
          placeholder: "Any Value",
          resource: "playback_method",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "description",
          value: "extra",
          module_name:"Video",
          callbackFocus: () => (this.fetchPlaybackMethod())
        },
        player_size: {
          model: "player_size",
          placeholder: "Any Value",
          resource: "player_size",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "description",
          value: "extra",
          module_name:"Video",
          callbackFocus: () => (this.fetchPlayerSize())
        },
        start_delay: {
          model: "start_delay",
          placeholder: "Any Value",
          resource: "start_delay",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "description",
          value: "extra",
          module_name:"Video",
          callbackFocus: () => (this.fetchStartDelay())
        },
        video_placement_type: {
          model: "video_placement_type",
          placeholder: "Any Value",
          resource: "video_placement_type",
          isNumeric: true,
          isFocus: true,
          isSearch: false,
          text: "description",
          value: "extra",
          module_name:"Video",
          callbackFocus: () => (this.fetchVideoPlacementType())
        }
      }
    },

    async setResources(){
      this.resources.ad_size = this.getModifierOption['ad_size'];
      this.resources.ad_position = this.getModifierOption['ad_position'];
      this.resources.environment_type = this.getModifierOption['environment_type'];
      this.resources.interstitial_flags = this.getModifierOption['interstitial_flags'];
      this.resources.interstitial_type = this.getModifierOption['interstitial_type'];
      this.resources.topframe = this.getModifierOption['topframe'];
      this.resources.video_api = this.getModifierOption['api_frameworks'];
      this.resources.content_rating = this.getModifierOption['content_rating'];
      this.resources.language = this.getModifierOption['language'];
      this.resources.auction_type = this.getModifierOption['auction_type'];
      this.resources.companion_required = this.getModifierOption['companion_required'];
      this.resources.inventory_source = this.getModifierOption['inventory_source'];
      this.resources.playback_method = this.getModifierOption['playback_method'];
      this.resources.player_size = this.getModifierOption['player_size'];
      this.resources.start_delay = this.getModifierOption['start_delay'];
      this.resources.video_placement_type = this.getModifierOption['video_placement_type'];
    }
  },
  watch:{
    async "getModifierOption"(val){
      await this.setResources();
    }
  }
});
